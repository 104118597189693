import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import BackgroundElements from './components/BackgroundElements';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
// import Header from './components/Header'; // Comment out until we create this component
import Footer from './components/Footer';
import type { FormState } from './types';

const LandingPage = lazy(() => import('./components/LandingPage'));
const InformationPage = lazy(() => import('./components/InformationPage'));
const ServiceForm = lazy(() => import('./components/ServiceForm'));

// Add error logging service


class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error('Application error:', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-emerald-50 to-white">
          <div className="text-center p-6">
            <h2 className="text-2xl font-bold text-emerald-800 mb-2">Something went wrong</h2>
            <p className="text-gray-600 mb-4">Please try refreshing the page.</p>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

function LoadingSpinner() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
    </div>
  );
}

function AppContent() {
  const [formState, setFormState] = useState<FormState>({});
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);

  const handlePathSelect = (path: 'INFORMATION' | 'SERVICES') => {
    if (path === 'INFORMATION') {
      navigate('/about');
    } else {
      setFormState({ path: 'SERVICES' });
    }
  };

  const handleReset = () => {
    setFormState({});
    navigate('/');
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-emerald-50 to-white">
        <div className="text-center p-6">
          <h2 className="text-2xl font-bold text-emerald-800 mb-2">Something went wrong</h2>
          <p className="text-gray-600 mb-4">{error.message}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          >
            Refresh Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-emerald-50 to-white">
      <main className="flex-1">
        <BackgroundElements />
        
        <div className="relative z-10">
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route 
                path="/" 
                element={
                  formState.path ? (
                    <ServiceForm 
                      formState={formState}
                      onUpdateState={setFormState}
                      onReset={handleReset}
                    />
                  ) : (
                    <LandingPage onPathSelect={handlePathSelect} />
                  )
                }
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/cookies" element={<CookiePolicy />} />
              <Route path="/services" element={
                <ServiceForm 
                  formState={{ path: 'SERVICES' }}
                  onUpdateState={setFormState}
                  onReset={handleReset}
                />
              } />
              <Route path="/about" element={<InformationPage />} />
            </Routes>
          </Suspense>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AppContent />
      </Router>
    </ErrorBoundary>
  );
}