import { Link } from 'react-router-dom';
import { Building, Leaf } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-white border-t border-emerald-100">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          {/* Logo Section */}
          <div className="flex items-center gap-8">
            <Link 
              to="/" 
              className="flex items-center gap-2 hover:opacity-80 transition-opacity"
            >
              <div className="relative">
                <Building className="w-6 h-6 text-emerald-600" />
                <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-emerald-500 rounded-full flex items-center justify-center">
                  <Leaf className="w-2 h-2 text-white" />
                </div>
              </div>
              <span className="text-lg font-semibold bg-gradient-to-r from-emerald-600 to-green-600 bg-clip-text text-transparent">
                BuildGreen
              </span>
            </Link>

            {/* Quick Links */}
            <div className="flex items-center gap-6">
              <Link 
                to="/about" 
                className="text-sm text-gray-600 hover:text-emerald-600 transition-colors"
              >
                Information
              </Link>
              <Link 
                to="/services" 
                className="text-sm text-gray-600 hover:text-emerald-600 transition-colors"
              >
                Services
              </Link>
            </div>
          </div>

          {/* Legal Links and Copyright */}
          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="flex items-center gap-4 text-sm">
              <Link 
                to="/privacy" 
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                Privacy
              </Link>
              <Link 
                to="/terms" 
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                Terms
              </Link>
              <Link 
                to="/cookies" 
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                Cookies
              </Link>
            </div>
            <div className="text-sm text-gray-400">
              © 2024 BuildGreen
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
} 