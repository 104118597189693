import React from 'react';

export default function BackgroundElements() {
  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {/* Gradient Orbs */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-emerald-200 rounded-full 
                    opacity-20 blur-3xl animate-floating-shapes" />
      <div className="absolute bottom-1/3 right-1/4 w-64 h-64 bg-green-200 rounded-full 
                    opacity-20 blur-3xl animate-floating-shapes" style={{ animationDelay: '-2s' }} />
      
      {/* Abstract Shapes */}
      <div className="absolute top-1/2 left-1/3 w-32 h-32 border-4 border-emerald-200 
                    rounded-full opacity-30 animate-spin-slow" />
      <div className="absolute bottom-1/4 right-1/3 w-48 h-48 border-4 border-green-200 
                    rounded-full opacity-30 animate-spin-reverse" />
      
      {/* Decorative Lines */}
      <div className="absolute top-1/4 right-1/4 w-px h-32 bg-gradient-to-b from-emerald-200 
                    to-transparent opacity-30 animate-floating-shapes" 
           style={{ animationDelay: '-1s' }} />
      <div className="absolute bottom-1/3 left-1/3 w-px h-48 bg-gradient-to-b from-green-200 
                    to-transparent opacity-30 animate-floating-shapes" 
           style={{ animationDelay: '-3s' }} />

      {/* New Elements */}
      {/* Geometric Patterns */}
      <div className="absolute top-1/3 left-1/2 w-24 h-24 border-2 border-emerald-300 
                    rotate-45 opacity-20 animate-pulse" />
      <div className="absolute bottom-1/4 left-1/4 w-16 h-16 
                    bg-gradient-to-r from-emerald-200 to-green-200 
                    rotate-12 opacity-10 animate-floating-shapes"
           style={{ animationDelay: '-4s' }} />

      {/* Dots Pattern */}
      <div className="absolute top-1/2 right-1/4 grid grid-cols-3 gap-2 opacity-20">
        {[...Array(9)].map((_, i) => (
          <div key={i} className="w-2 h-2 rounded-full bg-emerald-300 animate-pulse" 
               style={{ animationDelay: `${i * 0.2}s` }} />
        ))}
      </div>

      {/* Wave Pattern */}
      <div className="absolute bottom-1/3 right-1/2 w-64 h-px bg-gradient-to-r 
                    from-transparent via-emerald-300 to-transparent opacity-30 
                    animate-wave" />

      {/* Blurred Accent */}
      <div className="absolute top-1/3 right-1/3 w-48 h-48 bg-emerald-100 
                    rounded-full opacity-10 blur-3xl animate-pulse-slow" />
    </div>
  );
}